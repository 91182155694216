var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"home_top"},[_c('div',{staticClass:"top_text"},[_c('router-link',{attrs:{"to":"/demo"}},[_c('span',{staticClass:"select_a"},[_vm._v("Demo预览")])]),_c('router-link',{attrs:{"to":"/call"}},[_c('span',[_vm._v("联系我们")])]),_c('router-link',{attrs:{"to":"/developer/platform"}},[_c('span',[_vm._v("更新日志")])])],1)]),_c('div',{staticClass:"product"},[_c('Mytitle',{attrs:{"data":{
        h: '我们的产品',
      }}}),_c('div',{staticClass:"pro_img"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"rigthTwo"},[_vm._m(3),_vm._m(4),_vm._m(5),_c('router-link',{attrs:{"to":"/product?id=0"}},[_c('div',{staticClass:"more"},[_c('span',[_vm._v("了解更多")])])])],1)])],1),_c('div',{staticClass:"advantage"},[_c('Mytitle',{attrs:{"data":{
        h: '我们的优势',
        p: '以用户需求为导向，不断优化产品，带给用户极致体验感',
      }}}),_vm._m(6)],1),_c('div',{staticClass:"scene"},[_c('img',{staticClass:"img",class:_vm.sceIndex == 0 ? 'show' : '',attrs:{"src":require("@/assets/home/index/应用场景_直播场景首图.png"),"alt":""}}),_c('img',{staticClass:"img",class:_vm.sceIndex == 1 ? 'show' : '',attrs:{"src":require("@/assets/home/index/应用场景_视频场景首图.png"),"alt":""}}),_c('img',{staticClass:"img",class:_vm.sceIndex == 2 ? 'show' : '',attrs:{"src":require("@/assets/home/index/应用场景_智慧屏场景首图.png"),"alt":""}}),_vm._m(7),_c('div',{staticClass:"sce_conter"},[_c('ul',{staticClass:"list"},_vm._l((_vm.sceneList),function(item,index){return _c('li',{key:index,class:index == _vm.sceIndex ? 'sceIndex' : '',on:{"click":function($event){return _vm.showItem(item, index)},"mousemove":function($event){return _vm.showItem(item, index)}}},[_c('img',{staticClass:"icon",attrs:{"src":item.icon}}),_vm._v(" "+_vm._s(item.title)+" ")])}),0),_c('div',{staticClass:"text"},[_c('h2',[_vm._v(_vm._s(_vm.sceneShow.smallTit))]),_c('p',[_vm._v(" "+_vm._s(_vm.sceneShow.text)+" ")]),_c('a',{on:{"click":function($event){return _vm.toScene(_vm.sceneShow.id)}}},[_vm._v(" 了解详情")])])])]),_c('div',{staticClass:"cloud"},[_c('Mytitle',{attrs:{"data":{
        h: '支持云平台',
        p: 'TiFancy美颜SDK/萌颜SDK可支持所有主流云平台',
      }}}),_vm._m(8),_c('vue-lazy-component',{attrs:{"tagName":"div"}},[_c('div',{ref:"Box",staticClass:"clo_num"},_vm._l((_vm.cloudList),function(num,i){return _c('div',{key:i},[_c('h2',[_c('countTo',{attrs:{"slot":"skeleton","startVal":num.startVal,"endVal":num.endVal,"duration":num.duration},slot:"skeleton"}),_vm._v(_vm._s(num.unit)+" ")],1),_c('p',[_vm._v(_vm._s(num.text))])])}),0)])],1),_c('div',{staticClass:"news"},[_c('Mytitle',{attrs:{"data":{
        h: '新闻动态',
      }}}),_c('div',{staticClass:"news_conter"},[_c('div',{staticClass:"con_img"},[_vm._m(9),_vm._m(10),_c('div',{staticClass:"con_div"},[_vm._m(11),_c('span',{staticClass:"text_span"},[_vm._v("产品更新 "),_c('ul',{staticClass:"text_ul"},[_c('li',[_c('router-link',{attrs:{"to":"/developer/platform"}},[_c('a',{attrs:{"href":"#"}},[_vm._v("Tifancy带来全新功能与体验 "),_c('span',[_vm._v("2021-05-05")])])])],1),_c('li',[_c('router-link',{attrs:{"to":"/developer/platform"}},[_c('a',{attrs:{"href":"#"}},[_vm._v("Tifancy让升级不止于此！ "),_c('span',[_vm._v("2021-04-15")])])])],1)])])])]),_vm._m(12)])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"leftOne"},[_c('div',{staticClass:"div_img"},[_c('img',{attrs:{"src":require("@/assets/home/index/首页-全局美颜.jpg"),"alt":"全局美颜"}}),_c('span',{staticClass:"none"},[_vm._v("全局美颜")]),_c('div',{staticClass:"div_mask"},[_c('p',[_c('span',[_vm._v("全局美颜")]),_c('br'),_vm._v(" 「TiFancy 美颜SDK」集成精致的磨皮、 美白、粉嫩，饱和等美颜功能，精准控制美颜特效和全局影像美化 ")])])]),_c('div',{staticClass:"div_img"},[_c('img',{attrs:{"src":require("@/assets/home/index/首页-精致美型.jpg"),"alt":"精致美型"}}),_c('span',{staticClass:"none"},[_vm._v("精致美型")]),_c('div',{staticClass:"div_mask"},[_c('p',[_c('span',[_vm._v("精致美型")]),_c('br'),_vm._v(" 「TiFancy 美颜SDK」实时美型可以自由放大眼睛，收缩脸颊， 微调整额头、下巴、嘴形、瘦鼻等不失真，又能够完美调整脸型，搞定各个场合的自拍和Vlog ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"leftTwo"},[_c('div',{staticClass:"div_img"},[_c('img',{attrs:{"src":require("@/assets/home/index/首页--精美面具.jpg"),"alt":"精致美型"}}),_c('span',{staticClass:"none"},[_vm._v("精美面具")]),_c('div',{staticClass:"div_mask"},[_c('p',[_c('span',[_vm._v("精美面具")]),_c('br'),_vm._v(" 「TiFancy 美颜SDK」基于高效的3D图形渲染技术，动态实现精致的3D面具特效，完美贴合人脸。 ")])])]),_c('div',{staticClass:"div_img"},[_c('img',{attrs:{"src":require("@/assets/home/index/首页--动态贴纸gif.gif"),"alt":"精致美型"}}),_c('span',{staticClass:"none"},[_vm._v("动态贴纸")]),_c('div',{staticClass:"div_mask"},[_c('p',[_c('span',[_vm._v("动态贴纸")]),_c('br'),_vm._v(" 「TiFancy 美颜SDK」基于高效的人脸识别和动态渲染技术，实现人脸动态贴纸特效。提供二百款贴纸，友好支持个性化定制。 ")])])]),_c('div',{staticClass:"div_img"},[_c('img',{attrs:{"src":require("@/assets/home/index/首页--动态礼物gif.gif"),"alt":"精致美型"}}),_c('span',{staticClass:"none"},[_vm._v("动态礼物")]),_c('div',{staticClass:"div_mask"},[_c('p',[_c('span',[_vm._v("动态礼物")]),_c('br'),_vm._v(" 「TiFancy 美颜SDK」集合多种有趣丰富的送礼物特效，充分满足您的直播送礼物的应用需求。礼物支持进行用户自定义，丰富您的直播、短视频应用。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rigthOne"},[_c('div',{staticClass:"div_img"},[_c('img',{attrs:{"src":require("@/assets/home/index/首页--美妆特效.png"),"alt":"精致美型"}}),_c('span',{staticClass:"none"},[_vm._v("美妆特效")]),_c('div',{staticClass:"div_mask"},[_c('p',[_c('span',[_vm._v("美妆特效")]),_c('br'),_vm._v(" 「TiFancy 美颜SDK」依托于人脸跟踪技术，实现实时美妆，瞬间拥有靓丽妆容 ")])])]),_c('div',{staticClass:"div_img"},[_c('img',{attrs:{"src":require("@/assets/home/index/首页--哈哈镜.png"),"alt":"精致美型"}}),_c('span',{staticClass:"none"},[_vm._v("哈哈镜")]),_c('div',{staticClass:"div_mask"},[_c('p',[_c('span',[_vm._v("哈哈镜")]),_c('br'),_vm._v(" 「TiFancy 美颜SDK」集成多款哈哈镜特效，各类恶搞效果，秒变如花妆，蛇精脸，或者冻成冰块方脸，增添相机和视频乐趣。 ")])])]),_c('div',{staticClass:"div_img"},[_c('img',{attrs:{"src":require("@/assets/home/index/首页--滤镜特效.png"),"alt":"精致美型"}}),_c('span',{staticClass:"none"},[_vm._v("滤镜特效")]),_c('div',{staticClass:"div_mask"},[_c('p',[_c('span',[_vm._v("滤镜特效")]),_c('br'),_vm._v(" 「TiFancy 美颜SDK」拥有近100套精美的滤镜可供选择，友好支持个性化定制，可实时切换和效果预览 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div_img"},[_c('img',{attrs:{"src":require("@/assets/home/index/首页--抖动特效.png"),"alt":"精致美型"}}),_c('span',{staticClass:"none"},[_vm._v("抖动特效")]),_c('div',{staticClass:"div_mask"},[_c('p',[_c('span',[_vm._v("抖动特效")]),_c('br'),_vm._v(" 「TiFancy 美颜SDK」集合多种有趣稳定的抖音特效，充分满足您的抖音应用需求和个性化定制，丰富您的直播、短视频应用。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div_img"},[_c('img',{attrs:{"src":require("@/assets/home/index/首页--长腿瘦身.png"),"alt":"精致美型"}}),_c('span',{staticClass:"none"},[_vm._v("长腿瘦身")]),_c('div',{staticClass:"div_mask"},[_c('p',[_c('span',[_vm._v("水印LOGO")]),_c('br'),_vm._v(" 「TiFancy 美颜SDK」为特效相机、直播、短视频提供实时视频和图片添加水印功能，水印图片、位置以及添加方式可自定义。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"div_img"},[_c('img',{attrs:{"src":require("@/assets/home/index/首页--水印LOGO.png"),"alt":"精致美型"}}),_c('span',{staticClass:"none"},[_vm._v("水印LOGO")]),_c('div',{staticClass:"div_mask"},[_c('p',[_c('span',[_vm._v("人像抠图")]),_c('br'),_vm._v(" 「TiFancy 美颜SDK」基于AI人像背景分割技术，实时进行直接的背景替换或背景虚化处理，无需依赖绿幕，配合各类趣味脑洞背景素材，提升娱乐体验。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"adv_conter"},[_c('div',{staticClass:"friendly"},[_c('img',{attrs:{"src":require("@/assets/home/index/icon_advantage_youhao@2x.png")}}),_c('h3',[_vm._v("集成友好")]),_c('p',[_vm._v("SDK的集成方式简便，接口友好易接入，让您轻松使用")])]),_c('div',{staticClass:"efficient"},[_c('img',{attrs:{"src":require("@/assets/home/index/icon_advantage_gaoxiao@2x.png")}}),_c('h3',[_vm._v("稳定高效")]),_c('p',[_vm._v(" 具备广泛的用户基础，提供毫秒级别的接口调用速度，有效解决应用过程中阻塞卡顿的问题 ")])]),_c('div',{staticClass:"edit"},[_c('img',{attrs:{"src":require("@/assets/home/index/icon_advantage_bianji@2x.png")}}),_c('h3',[_vm._v("自定义编辑")]),_c('p',[_vm._v(" 依托高效便捷的人脸道具编辑工具，可以定制丰富有趣的2D、3D贴纸道具 ")])]),_c('div',{staticClass:"compatible"},[_c('img',{attrs:{"src":require("@/assets/home/index/icon_advantage_jianrong@2x.png")}}),_c('h3',[_vm._v("良好兼容")]),_c('p',[_vm._v("多平台易使用。目前友好支持腾讯云、七牛云等主流云平台推流应用")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sce_title"},[_c('h1',[_vm._v("多场景应用")]),_c('span',{staticClass:"line"}),_c('p',[_vm._v(" TiFancy美颜SDK满足直播、视频、智慧屏等多样化应用场景的美颜特效需求 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"clo_img"},[_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_1@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_2@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_3@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_4@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_5@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_6@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_7@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_8@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_9@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_10@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_11@2x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/index/img_hezuo_12@2x.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"con_div"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("@/assets/home/index/首页--拓幻资讯.png"),"alt":""}})]),_c('span',{staticClass:"text_span"},[_vm._v("拓幻资讯 "),_c('ul',{staticClass:"text_ul"},[_c('li',[_c('a',{attrs:{"target":"_blank","href":"https://mp.weixin.qq.com/s?__biz=MzU5NDMzNDI0Ng==&mid=2247487360&idx=2&sn=26e2105cc7810832a67006549430f743&chksm=fe0388c9c97401df5426a3f9fde47c9532d359f34b611be620fdb50d915b73833c93715892e7&token=240555228&lang=zh_CN"}},[_vm._v("新功能即将来袭，终于可以用了！ "),_c('span',[_vm._v("2021-03-30")])])]),_c('li',[_c('a',{attrs:{"target":"_blank","href":"https://mp.weixin.qq.com/s?__biz=MzU5NDMzNDI0Ng==&mid=2247487320&idx=1&sn=0e8d79e08c07e6dde774b5294001d48d&chksm=fe038811c97401074c8eeb2db2e1e45425325c434532e29e71ecd6cade82b9cbf8b9351c3a38&token=240555228&lang=zh_CN#rd"}},[_vm._v("云粉们看过来！这里有你们喜欢... "),_c('span',[_vm._v("2021-03-28")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"con_div"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("@/assets/home/index/首页--行业信息.png"),"alt":""}})]),_c('span',{staticClass:"text_span"},[_vm._v("行业信息 "),_c('ul',{staticClass:"text_ul"},[_c('li',[_c('a',{attrs:{"target":"_blank","href":"https://www.chinairn.com/news/20150712/104455436.shtml"}},[_vm._v("美颜经济兴起,美颜类应用有多少... "),_c('span',[_vm._v("2021-03-30")])])]),_c('li',[_c('a',{attrs:{"target":"_blank","href":"https://www.chyxx.com/industry/201912/818377.html"}},[_vm._v("2019年上半年中国拍摄美化行业... "),_c('span',[_vm._v("2021-03-28")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("@/assets/home/index/首页--产品更新.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"con_text"},[_c('div',{staticClass:"text"},[_c('h3',[_vm._v(" 体验我们的Demo "),_c('img',{attrs:{"src":require("@/assets/home/index/icon_demo@2x.png"),"alt":""}})]),_c('p',{staticClass:"p_one"},[_vm._v(" 美颜SDK/萌颜SDK Demo - TiFancy（iOS版本，Android版本）集成了拓幻科技的部分核心技术， 囊括了全局美颜、精致美型、微整形、动态贴纸、滤镜特效，美妆特效、抖动特效、互动贴纸、水印logo、精致面具、哈哈镜、一键美颜、脸型精修、绿幕特效、AI人像抠图、长腿瘦身等特效，提供了视频拍照、图片编辑等场景下的体验。 ")]),_c('p',{staticClass:"p_two"},[_vm._v(" 您可以通过扫描识别右侧二维码，免费下载，轻松体验TiFancy美颜SDK带来的精致化娱乐方式！ ")])]),_c('img',{attrs:{"src":require("@/assets/home/index/demo@2x.png")}})])
}]

export { render, staticRenderFns }